html, body, #app {
    height: 100%;
}
.dark-theme {
    background: #001928;
    background: linear-gradient(40deg, #001928, #002135 100%);
    height: 100%;
    overflow: auto;
    color: #eee;
    min-height: 100vh;
    .card {
        background-color: #002a44;
        color: #eee;
    }
    .list-group-item {
        color: #eee;
        background-color: #002a44;
    }
    .list-group-item-action {
        background-color: #002a44;
        transition: .3s;
    }
    .list-group-item-action:hover {
        background-color: #003f69;
    }
    .list-group-item.active {
        color: #fff;
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
    .modal-content {
        background-color: #002a44;
        border: 1px solid rgba(0, 0, 0, 0.5);
    }
    .modal-header {
        border-bottom: 1px solid #dee2e6;
    }
    .accordion-button {
        background-color: #283041;
        color: #fff;
    }
    .accordion-button:not(.collapsed) {
        background: #0b5ed7;
    }
    .accordion-button::after {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        background-color: #73879c;
    }
    .accordion-item {
        background-color: #002a44;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        color: #fff;
    }
    .btn-outline-secondary {
        color: #abb2b7;
        border-color: #abb2b7;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        border-color: #fff;
    }
    .form-select {
        background-color: #eee;
    }
    .table, .table th, .table td {
        background-color: #002a44;
        //color: #fff;
    }
    .table-hover > tbody > tr > * {
        transition: .2s;
    }
    .table-hover > tbody > tr:hover > * {
        background-color: #283041;
        color: #fff;
    }
    .base-link {
        color: #fff;
        text-decoration: none;
    }
    .base-link:hover {
        text-decoration: underline;
    }
}
